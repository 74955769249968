<template>
    <transition name="fade">
        <div class="toasts" v-show="visible">
        {{ text }}
        </div>
    </transition>
</template>

<script>
import { ref, onMounted } from "vue";

export default {
    name: 'toast',
    props: {
        text: {
            type: String,
            default: "",
        },
  },
  setup() {
    const visible = ref(false);

    onMounted(() => {
      visible.value = true;
    });
    
    return { visible };
  },
}
</script>

<style scoped lang="scss">
.toasts {
    padding: 8px 24px;
    position: fixed;
    z-index: 9;
    left: 50%;
    bottom: 50%;
    border: 1px solid #e4e4e4;
    background: rgba(17, 17, 17, 0.7);
    box-shadow: 0px 10px 50px 0px;
    color: #fff;
    border-radius: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    transform: translateX(-50%) translateY(-50%);
    white-space: pre;
}

.fade-enter-from {
    opacity: 0;
}

.fade-enter-active {
    transition: all 0.5s;
}

.fade-enter-to {
    opacity: 1;
}
</style>