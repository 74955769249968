(function (window) {
    // 请求接口地址 如果没有配置自动获取当前网址路径
    //const VUE_APP_API_URL = 'https://localhost:5001/'
    const VUE_APP_API_URL = 'https://apifact.szmjj.net'
    const VUE_APP_IMAGE_URL = 'https://www.szmjj.net/picture/'

    const HREF = window.location?.href

    const Setting = {
        href: HREF,
        // 接口请求地址
        apiBaseURL: VUE_APP_API_URL,
        // 页面切换时，是否显示模拟的进度条
        /** 图片域名 */
        imageBaseUrl: VUE_APP_IMAGE_URL,
        showProgressBar: true,
        /** 接口无权限 */
        noApiAuth: 401,
    }

    module.exports = Setting
})
(typeof window == "undefined" ? global : window);