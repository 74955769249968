import { createApp  } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import locale from 'element-plus/es/locale/lang/zh-cn'
import $ from 'jquery'
import echarts from './echarts/echarts.js'
import Print from 'vue3-print-nb'
import JsBarcode from 'jsbarcode'
//import horizontalScroll from 'el-table-horizontal-scroll'

//import VForm3 from 'vform3-builds'  //引入VForm3库

import 'element-plus/dist/index.css'
import 'nprogress/nprogress.css'
import './static/css/element-ui-reset.scss'
//import 'vform3-builds/dist/designer.style.css'  

const app = createApp(App)

// 全局引入Element ui icon
import * as ElIcons from '@element-plus/icons-vue'
for (const name in ElIcons){
    app.component(name, ElIcons[name])
}

// 全局 toast 方法
import { toast } from '@/components/Toast/toast'
app.config.globalProperties.$toast = toast;

//全局Echarts
app.config.globalProperties.$echarts = echarts;

// 全局JQ
app.config.globalProperties.$jq = $
//app.component('barcode', Vue3Barcode);
app.use(store)
app.use(router)
app.use(Print)
app.use(JsBarcode)
//app.use(horizontalScroll)
app.use(ElementPlus, { locale })
//app.use(VForm3)

app.mount('#app')

// element-plus的el-table问题
const debounce = (fn, delay) => {
    let timer
     return (...args) => {
       if (timer) {
         clearTimeout(timer)
       }
       timer = setTimeout(() => {
         fn(...args)
       }, delay)
     }
  }
    
  const _ResizeObserver = window.ResizeObserver;
  window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
     constructor(callback) {
       callback = debounce(callback, 200);
       super(callback);
     }
  }