import request from '@/utils/request'

/**
 * 系统权限 Api 接口集合
 */
/** 获取菜单列表 */
export const GetUserMenuList = () => {
    return request({
        method: 'get',
        url: '/shop.admin/api/Permission/GetUserMenuList',
    })
}

//#region 菜单收藏

/** 收藏列表 */
export const userFavMenuGetList = data => {
    return request({
        method: 'post',
        url: '/api/UserFavMenu/GetList',
        params: data
    })
}

/** 操作，收藏和取消收藏 */
export const userFavMenuAction = data => {
    return request({
        method: 'get',
        url: '/api/UserFavMenu/Action',
        params: data
    })
}
//#endregion

/** 查询用户的附加权限和排除权限 */ 
export const getAttachExcludeMenus = data => {
    return request({
        method: 'get',
        url: '/shop.admin/api/Permission/GetAttachExcludeMenus',
        params: data,
    })
};

/** 给用户附加或排除权限 */ 
export const attachExcludeMenu = data => {
    return request({
        method: 'post',
        url: '/shop.admin/api/Permission/AttachExcludeMenu',
        data,
    })
};

/** 删除用户的附加或排除权限 */ 
export const deleteAttachExclude = data => {
    return request({
        method: 'post',
        url: '/shop.admin/api/Permission/DeleteAttachExclude',
        params: data,
    })
};

/** 获取用户的菜单权限列表 */ 
export const getNeedAttachExcludeMenus = data => {
    return request({
        method: 'GET',
        url: '/shop.admin/api/Permission/GetNeedAttachExcludeMenus',
        params: data,
    })
};

/** 根据菜单路由名称获取页面按钮权限 */ 
export const getViewBtuPermission = data => {
    return request({
        method: 'GET',
        url: '/shop.admin/api/Permission/GetViewBtuPermission',
        params: data,
    })
};

/** 获取用户权限列表 */ 
export const getAllMenusByCode = data => {
    return request({
        method: 'GET',
        url: '/shop.admin/api/Permission/GetAllMenusByCode',
        params: data,
    })
};

/** 获取某个用户组拥有的权限 */ 
export const getGroupMenus = data => {
    return request({
        method: 'GET',
        url: '/shop.admin/api/Permission/GetGroupMenus',
        params: data,
    })
};

/** 获取所有菜单及标识该用户组是否拥有该权限 */ 
export const getGroupMenusMarkMe = data => {
    return request({
        method: 'GET',
        url: '/shop.admin/api/Permission/GetGroupMenusMarkMe',
        params: data,
    })
};

/** 用户组拥有的权限的创建调整变更 */ 
export const permissionCreate = data => {
    return request({
        method: 'POST',
        url: '/shop.admin/api/Permission/ChangeGroupRight',
        data,
    })
};



/** 获取用户组列表（不分页且标识出自己包含的用户组） */ 
export const userGroupGetList = data => {
    return request({
        method: 'GET',
        url: '/shop.admin/api/Permission/GetUserGroupList',
        params: data,
    })
};

/** 分页获取用户组列表 */ 
export const userGroupListByPage = data => {
    return request({
        method: 'POST',
        url: '/shop.admin/api/Permission/GetUserGroupByPage',
        data,
    })
};

/** 新增或修改用户组 */ 
export const userGroupCreateOrUpdate = data => {
    return request({
        method: 'POST',
        url: '/shop.admin/api/Permission/CreateOrUpdateGroup',
        data,
    })
};

/** 获取用户组实体 */ 
export const GetUserGroup = data => {
    return request({
        method: 'GET',
        url: '/shop.admin/api/Permission/GetUserGroup',
        params: data,
    })
};

/** 删除菜单实体 */ 
export const userGroupDelete = data => {
    return request({
        method: 'POST',
        url: '/shop.admin/api/UserGroup/Delete',
        params: data,
    })
};

/** 新增或修改用户拥有用户组 */ 
export const userGroupCreateOrUpdateGroup = data => {
    return request({
        method: 'POST',
        url: '/shop.admin/api/Permission/ChangeGroupUser',
        data,
    })
};

/** 新增或删除用户组拥有的用户 */ 
export const userGroupCreateOrDelete = data => {
    return request({
        method: 'POST',
        url: '/shop.admin/api/Permission/ChangeGroupUser',
        data,
    })
};

/** 获取该用户组的用户列表 */ 
export const getUserListOfGroup = data => {
    return request({
        method: 'get',
        url: '/shop.admin/api/Permission/GetUserListOfGroup',
        params: data,
    })
};

/** 获取除去该用户组的用户的用户列表 */ 
export const getUserListOfGroupRemoveThis = data => {
    return request({
        method: 'POST',
        url: '/shop.admin/api/UserGroup/GetUserListOfGroupRemoveThis',
        params: data,
    })
};



/** 根据当前用户返回城市总部所有权限 */ 
export const userCustomerList = data => {
    return request({
        method: 'POST',
        url: '/shop.admin/api/SysUserCustomer/List',
        params: data,
    })
};

/** 编辑保存城市总部权限 */ 
export const userCustomerEdit = data => {
    return request({
        method: 'POST',
        url: '/shop.admin/api/SysUserCustomer/Edit',
        data,
    })
};



/** 根据当前用户返回活动渠道所有权限 */ 
export const userActivityChannelList = data => {
    return request({
        method: 'POST',
        url: '/shop.admin/api/System/UserActivityChannel/List',
        params: data,
    })
};

/** 编辑保存活动渠道权限 */ 
export const userActivityChannelEdit = data => {
    return request({
        method: 'POST',
        url: '/shop.admin/api/System/UserActivityChannel/Edit',
        data,
    })
};



/** 根据当前用户返回销售组织所有权限 */ 
export const userOrgList = data => {
    return request({
        method: 'POST',
        url: '/shop.admin/api/UserOrg/List',
        params: data,
    })
};

/** 编辑保存销售组织权限 */ 
export const userOrgEdit = data => {
    return request({
        method: 'POST',
        url: '/shop.admin/api/UserOrg/Edit',
        data,
    })
};

/** 根据当前用户返回客户组所有权限 */ 
export const userSalesGroupList = data => {
    return request({
        method: 'POST',
        url: '/shop.admin/api/UserSalesGroup/List',
        params: data,
    })
};

/** 编辑保存客户组权限 */ 
export const userSalesGroupEdit = data => {
    return request({
        method: 'POST',
        url: '/shop.admin/api/UserSalesGroup/Edit',
        data,
    })
};