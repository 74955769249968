import axios from 'axios'
import { ElMessage } from 'element-plus'
import { getCookie,removeCookies } from '@/utils/util'
import Setting from '@/setting'
import router from '@/router'
import VueCookies from 'vue-cookies'

const service = axios.create({
    baseURL: Setting.apiBaseURL,
    timeout: 1800000, // 请求超时时间
    headers: { 
        "Content-Type": "application/json",
    }
    // ,
    // transformRequest: [function (data) {
    //     let ret = ''
    //     for (let it in data) {
    //         ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
    //     }
    //     return ret
    // }]
})

axios.defaults.withCredentials = true// 携带cookie

// 请求拦截器
service.interceptors.request.use(
    config => {
        if(config.method == "OPTIONS") return false
        config.headers["Authorization"] = "Bearer " +  getCookie('access_token')
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// 请求响应器
service.interceptors.response.use(

    response => {
        let status = response.status ? response.status : 0
        switch (status) {
            case 200:
                return response.data
            case 400: case 400011: case 400012:
                return Promise.reject(response.status || { msg: '未知错误' })
            case 410000:
            case 410001:
            case 410002:
                localStorage.clear()
                removeCookies('access_token')
                router.replace({ path: '/login' })
                break
            default:
                break
        }
    },
    error => {
        if(error.response == undefined || error.response.status == 404){
            ElMessage.error(error.toString())
            return Promise.reject(error)
        }
        else if(error.response.data.Status == Setting.noApiAuth){
            ElMessage.error(error.response.data.ResponseError.Message)
            sessionStorage.clear();
            removeCookies('access_token')
            router.replace({ path: '/login' })
            return
        }
        else{
            ElMessage.error(error.response.data.title)
            return Promise.reject(error.response.data.title)
        }
    }
)

export default service
