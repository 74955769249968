import { ElMessage } from 'element-plus';
import VueCookies from 'vue-cookies'
import { toast } from '../components/Toast/toast'

// 设置cookie时效
VueCookies.config("1d");

// 获取cookie
const getCookie = (key) => {
    return VueCookies.get(key)
}

// 设置cookie 统一转码
const setCookie = (key, value, time) => {
    VueCookies.set(key, value, { expires: time || 1 })
}


// 删除cookie
const removeCookies = (key) => {
    return VueCookies.remove(key);
}

// 获取文件
const importFile = file => {
    return function (resolve) {
        require([`@${file}`], resolve)
    }
}

/** 复制内容 */ 
const copyContent = (content) => {
    return new Promise((resolve, reject) => {
        navigator.clipboard.writeText(content)
            .then(() => {
                resolve();
                toast('复制成功', 2000);
            })
            .catch(e => {
                reject();
            })
    })
}

//时间格式转换 util.timeFormatConversion(item.CreationTime, "yyyy-MM-dd HH:mm:ss");
const timeFormatConversion = function (time, format) {
    time = String(time).length == 10 ? time * 1000 : time;
    var t = new Date(time);
    var tf = function (i) {
        return (i < 10 ? '0' : '') + i
    };
    return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function (a) {
        switch (a) {
            case 'yyyy':
                return tf(t.getFullYear());
                break;
            case 'MM':
                return tf(t.getMonth() + 1);
                break;
            case 'mm':
                return tf(t.getMinutes());
                break;
            case 'dd':
                return tf(t.getDate());
                break;
            case 'HH':
                return tf(t.getHours());
                break;
            case 'ss':
                return tf(t.getSeconds());
                break;
        }
    })
}

//验证手机号
let validateMobile = function (p) {
    p = p || '';
    var pattern = /^1[3456789]\d{9}$/;
    return !pattern.test(p);
}

//验证邮箱
let validateEmail = function (p) {
    p = p || '';
    return !p.match(/^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/);
}

// 转换金额格式、保留小数点位数
//number：输入的数字  n：需要保留几位小数
const thousandsSeparator = function (number, n) {

    //保留几位小数
    if (n != 0) {
        if (n > 0 && n <= 20) {
            n = n;
        } else {
            n = 2;
        }
    }

    number = parseFloat((number + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
    var sub_val = number.split(".")[0].split("").reverse();
    var sub_xs = number.split(".")[1];

    var show_html = "";
    for (var i = 0; i < sub_val.length; i++) {
        var m;
        if ((i + 1) % 3 == 0 && (i + 1) != sub_val.length) {
            m = ",";
        } else {
            m = "";
        }
        show_html += sub_val[i] + m;
    }

    if (n == 0) {
        return show_html.split("").reverse().join("");
    } else {
        return show_html.split("").reverse().join("") + "." + sub_xs;
    }
}

// 函数节流
// 在一定时间之内，限制一个方法只执行一次 // 原理：像定时器一样
const throttle = (funciton, delay) => {
    var startTime = new Date();
    return function(){
        var context = this;
        var args = arguments;
        var endTime = new Date();
        var resTime = endTime - startTime;
        //判断大于等于我们给的时间采取执行函数;
        if(resTime >= delay){
            funciton.apply(context, args);
            //执行完函数之后重置初始时间，等于最后一次触发的时间
            startTime = endTime;
        }
    }
}

/** 获取过去或未来时间 */
const getDateTime = dayNum => {
    var dateDay = new Date()

    //获取dayNum天后的日期
    dateDay.setDate(dateDay.getDate() + dayNum) 
    var y = dateDay.getFullYear()
    var m = (dateDay.getMonth() + 1) < 10 ? "0" + (dateDay.getMonth() + 1) : (dateDay.getMonth() + 1)
    var d = dateDay.getDate() < 10 ? "0" + dateDay.getDate() : dateDay.getDate()
    return y + "-" + m + "-" + d
}

const getDate = dayNum => {
    var dateDay = new Date()

    //获取dayNum天后的日期
    dateDay.setDate(dateDay.getDate() + dayNum) 
    var y = dateDay.getFullYear()
    var m = (dateDay.getMonth() + 1) < 10 ? "0" + (dateDay.getMonth() + 1) : (dateDay.getMonth() + 1)
    var d = dateDay.getDate() < 10 ? "0" + dateDay.getDate() : dateDay.getDate()
    return y + "-" + m + "-" + d
}

/** 下载文件流 */ 
const downloadFile = (file, fileName) => {
    const link = document.createElement('a');

    const blob = new Blob([ file ], { type: file.type })
    const objectUrl = URL.createObjectURL(blob)
    link.href = objectUrl
    // 获取文件后缀名
    const ext = fileName.split('.').pop()
    if (ext == "") ext = "xlsx"
    fileName = fileName.replace(ext, "")
    link.download = `${fileName}-${timeFormatConversion(new Date().getTime(), 'yyyy.MM.dd')}.${ext}`
    link.click()

    URL.revokeObjectURL(objectUrl) // 释放
}

function baseImg (dataurl) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),n = bstr.length,
        u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
    return new Blob([u8arr], {type: mime})
  }

  function  base64toFile(data, fileName) {
     const dataArr = data.split(",");
     const byteString = atob(dataArr[1]);
     const options = {
     type: "image/jpeg",
     endings: "native"
   };
    const u8Arr = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      u8Arr[i] = byteString.charCodeAt(i);
    }
    return new File([u8Arr], fileName + ".jpg", options);//返回文件流
 }


    /** 将返回的流数据转换为url（data: 文档流数据） */
   function  GetPrintPdfUrl(data) {
            let url = null
            let file = new Blob([data],{type:'application/pdf;chartset=utf-8'})
            if (window.createObjectURL != undefined) {
                // 通用
              url = window.createObjectURL(file)
            } else if (window.webkitURL != undefined) {
                // 兼容谷歌
              try {
                url = window.webkitURL.createObjectURL(file)
              } catch (error) {
    
              }
            } else if (window.URL != undefined) {
                // 兼容其他
              try {
                url = window.URL.createObjectURL(file)
            } catch (error) {
  
              }
            }
              // 将转化后url赋值
            return url
    }  


function arrToTree(arr){
    let copyArr = JSON.parse(JSON.stringify(arr))
    copyArr.forEach(item =>{
         arr.forEach(v =>{
             if(item.menuId === v.fatherId){
                 if(item.children){
                     item.children.push(v)
                 }else{
                     item.children = [v]
                 }
             }
         })
    })

    return copyArr
}

export {
    getCookie,
    setCookie,
    removeCookies,
    copyContent,
    timeFormatConversion,
    validateMobile,
    validateEmail,
    thousandsSeparator,
    throttle,
    arrToTree,
    downloadFile,
    getDateTime,
    getDate,
    baseImg,
    GetPrintPdfUrl,
    base64toFile,
}